import { routes as dashboardRoutes } from '../dashboard/routes.js'
import { routes as databaseRoutes } from '../database/routes.js'
import { routes as subscriptionsRoutes } from '../subscriptions/routes.js'
import { routes as environmentsRoutes } from '../environments/routes.js'
import { routes as contentsRoutes } from '../contents/routes.js'
import { routes as supportRoutes } from '../support/routes.js'
import { routes as infrastructureRoutes } from '../infrastructure/routes.js'
import { routes as billingRoutes } from '../billing/routes.js'
import { routes as settingsRoutes } from '../settings/routes.js'
import { routes as administrationRoutes } from '../administration/routes.js'
import { routes as v2Routes } from '../v2/routes.js'

let routes = []

routes = routes.concat(dashboardRoutes)
routes = routes.concat(databaseRoutes)
routes = routes.concat(subscriptionsRoutes)
routes = routes.concat(environmentsRoutes)
routes = routes.concat(contentsRoutes)
routes = routes.concat(supportRoutes)
routes = routes.concat(infrastructureRoutes)
routes = routes.concat(billingRoutes)
routes = routes.concat(settingsRoutes)
routes = routes.concat(administrationRoutes)
routes = routes.concat(v2Routes)

export { routes }
