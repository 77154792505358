import { createApp } from 'vue'
import { store, key as storeKey } from '@/store/store'
import { worker } from './mocks/browser'
import { Quasar, Loading, Dialog, Notify, QTable, QTableProps } from 'quasar'
import './styles/quasar.scss'
import lang from 'quasar/lang/fr.js'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/mdi-v7/mdi-v7.css'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { useFavicon, useBrowserLocation, useTitle } from '@vueuse/core'
import UuidEncoder from 'uuid-encoder'
import { createPinia } from 'pinia'
import VueGtag from 'vue-gtag'
import { set as GASet } from 'vue-gtag'

import { QBtn, QBtnProps } from 'quasar'

import { loadAuthenticatedUserFromLocalStorage, getRoutes } from './models/core/AuthenticatedUser'

import DefaultLayout from '@/layouts/default/DefaultLayout.vue'
import PublicLayout from '@/layouts/public/PublicLayout.vue'
import MobileLayout from '@/layouts/mobile/MobileLayout.vue'
import OutlookAddInLayout from '@/layouts/outlook-add-in/OutlookAddInLayout.vue'

import BackOfficeApi from '@/services/api/BackOfficeApi'

// Global components
import PageTitle from '@/layouts/default/header/PageTitle.vue'
import PageContent from '@/layouts/default/main/PageContent.vue'
const api: BackOfficeApi = new BackOfficeApi()

const user = loadAuthenticatedUserFromLocalStorage()

api.setUser(user)

api
  .getUserApi()
  .checkVersion()
  .then((newVersionAvailable) => {
    console.log('new version available : ', newVersionAvailable)
    if (newVersionAvailable) {
      window.location.reload()
    }
  })

let routes = getRoutes(user)
const encoder = new UuidEncoder('base62')

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  if (to.name === 'NotFound' && localStorage.getItem('user') === null) {
    localStorage.setItem('redirectPath', to.path)
    next('/login')
  } else if (requiresAuth && localStorage.getItem('user')) {
    next()
  } else {
    next()
  }
})

// router.beforeEach((to, from, next) => {
//   if (localStorage.getItem("user") === null) {
//     sessionStorage.setItem("redirectPath", to.fullPath);
//     next({ name: "base/login" });
//   } else {
//     if (to.matched.some((record) => record.meta.requiresAuth)) {
//       if (localStorage.getItem("user") === null) {
//         next({
//           path: "/login",
//           params: { nextUrl: to.fullPath },
//         });
//       } else {
//         let user = JSON.parse(localStorage.getItem("user") || "{}");
//         if (to.matched.some((record) => record.meta.is_admin)) {
//           if (user.is_admin == 1) {
//             next();
//           } else {
//             next({ name: "dashboard/main" });
//           }
//         } else {
//           next();
//         }
//       }
//     } else if (to.matched.some((record) => record.meta.guest)) {
//       if (
//         localStorage.getItem("user") === null ||
//         to.matched.some((record) => record.name == "base/accept_invitation") ||
//         to.matched.some(
//           (record) => record.name == "base/invitation_accepted"
//         ) ||
//         to.matched.some((record) => record.name == "base/login")
//       ) {
//         next();
//       } else {
//         next({ name: "dashboard/main" });
//       }
//     } else {
//       next();
//     }
//   }
// });

store.state.user = user
store.state.routes = routes
store.state.api = api
api.setStore(store)

/** Gestion de l'image selon l'entreprise */
/** TODO: Replace by an API call */
/** ------------------------------------- */
const icon = useFavicon()
const title = useTitle()
const location = useBrowserLocation()
if (location.value.hostname) {
  if (location.value.hostname.includes('bewiz')) {
    // bewiz
    icon.value = 'https://www.bewiz.fr/wp-content/uploads/favicon.ico'
    title.value = 'Bewiz'
  } else if (location.value.hostname.includes('marvellup')) {
    // marvellup
    icon.value = 'https://uploads-ssl.webflow.com/612f3b9c9f40bd43274df45f/6137842d619a956f35772df0_Webclip%20(4).svg'
    title.value = "Marvell Up'"
  } else {
    // comeonlaw
    icon.value = 'https://uploads-ssl.webflow.com/60ae33936d219445306748b5/60ddf7fe26f9ec74f6da235b_Webclip%20(3).svg'
    title.value = 'ComeOnLaw'
  }
}

if (import.meta.env.MODE === 'development') {
  console.log('development mode: enabling msw')
  worker.start({
    onUnhandledRequest: 'bypass',
  })
}

const app = createApp(App)

// Register global components
app.component('PageTitle', PageTitle)
app.component('PageContent', PageContent)

const regex = /^\/clients\/([^\/]*)\/.*/
const foundClientIdInUrl = document.location.pathname.match(regex)
if (foundClientIdInUrl != undefined && localStorage.getItem('user') !== null) {
  // const clientId = uuidValidate(foundClientIdInUrl[1])
  //   ? foundClientIdInUrl[1]
  //   : encoder.decode(foundClientIdInUrl[1]);
  const clientId = foundClientIdInUrl[1]
  if (user) {
    user.clientId = clientId
    console.log('getting client')
    api
      .getUserApi()
      .getClientWithoutEnvironment(clientId)
      .then((result) => {
        store.state.currentClient = result

        if (user.environment.id !== result.environmentId) {
          console.log('client environment is different from user environment. switching environment')
          user.environment = result.environment
          if (user.environment.id == '1e9dc58f-5eed-4636-853f-091303a35969') {
            user.portal = 'PLATFORM'
          } else {
            user.portal = 'ENVIRONMENT'
          }
          console.log('user : ', user)
          store.commit('setUser', user)
          window.location.reload()
        }

        updateUser()
      })
      .catch((e) => {
        console.error('error : ', e)

        // console.log("client not found in this environment. let's try to find it in another environment")

        store.state.currentClient = undefined
        updateUser()
      })
  }
} else {
  updateUser()
}

function updateUser() {
  if (localStorage.getItem('user') !== null) {
    api
      .getUserApi()
      .getUser(user.id)
      .then((userInfos) => {
        user.permissions = userInfos.permissions
        user.environments = userInfos.environments
        user.subscriptionModules = userInfos.subscriptionModules

        if (user.portal == 'CLIENT') {
          if (user?.environments.length > 0) {
            if (user.environments[0].portalType == 'ENVIRONMENT') {
              console.log('updating portal type')
              user.portal = 'ENVIRONMENT'
              user.clientId = user.client
              // if (user.environments[0].client != null) {
              //   user.portal = 'PLATFORM'
              // }

              const newRoutes = getRoutes(user)

              // Clear all routes
              const oldRoutes = store.state.routes
              oldRoutes.forEach((route) => {
                router.removeRoute(route.name)
              })

              // Add  all new routes
              newRoutes.forEach((route) => {
                router.addRoute(route)
              })
            }
          }
        }

        store.commit('setUser', user)
        finishSetup()
      })
      .catch((e) => {
        console.error('error : ', e)
      })
  } else {
    finishSetup()
  }
}

function finishSetup() {
  app.component('default-layout', DefaultLayout)
  app.component('public-layout', PublicLayout)
  app.component('mobile-layout', MobileLayout)
  app.component('outlook-add-in-layout', OutlookAddInLayout)

  if (import.meta.env.MODE !== 'development') {
    Sentry.init({
      app,
      dsn: 'https://f7cf23dbceb44a1cb556e318886bd35c@o1188191.ingest.sentry.io/6308136',
      environment: import.meta.env.MODE,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', 'https://app.staging.marvellup.com/home', /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
    })
  }

  app.use(Quasar, {
    plugins: {
      Loading,
      Dialog,
      Notify,
    },
    config: {
      brand: {
        primary: '#4068c8',
        secondary: '#26A69A',
        accent: '#9C27B0',
        dark: '#1d1d1d',
        positive: '#40A080',
        negative: '#c84838',
        info: '#1090b4',
        warning: '#d89010',
      },
      notify: {
        /* look at QuasarConfOptions from the API card */
      },
    },
    lang: lang,
    extras: ['material-icons-round', 'material-icons-outlined', 'mdi-v7'],
  })

  const SetComponentDefaults = <T>(component: any, defaults: Partial<T>): void => {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
    Object.keys(defaults).forEach((prop: string) => {
      component.props[prop] =
        Array.isArray(component.props[prop]) === true || typeof component.props[prop] === 'function'
          ? {
              type: component.props[prop],
              default: (defaults as Record<string, any>)[prop],
            }
          : {
              ...component.props[prop],
              default: (defaults as Record<string, any>)[prop],
            }
    })
    /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
  }

  SetComponentDefaults<QBtnProps>(QBtn, {
    noCaps: true,
    unelevated: true,
  })

  SetComponentDefaults<QTableProps>(QTable, {
    pagination: {
      rowsPerPage: 10,
    },
  })

  app.use(router)
  const pinia = createPinia()
  app.use(pinia)
  app.use(store, storeKey)
  // app.use(Editor);

  interface LoggedUserProperties {
    custom_user_id: string
    // first_name: string;
    // last_name: string;
  }

  app.use(
    VueGtag,
    {
      config: {
        id: import.meta.env.GOOGLE_ANALYTICS_MEASUREMENT_ID,
      },
    },
    router,
  )

  if (user) {
    const userProperties: LoggedUserProperties = {
      custom_user_id: user.id,
      // first_name: user.firstName,
      // last_name: user.lastName,
    }
    GASet({ user_properties: userProperties })
    GASet({ user_id: user.id })
  }

  app.mount('#app')
}
