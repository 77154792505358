<script setup lang="ts">
import { ref, Ref, provide, computed } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import ImportFiles from './ImportFiles.vue'

interface Props {
  project: any
  currentDirectory: any
  dataTransferItems?: any
}

const props = defineProps<Props>()

const emit = defineEmits([...useDialogPluginComponent.emits, 'addItemClick', 'editItemClick', 'update:visible'])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()
const isLoading: Ref<boolean> = ref(false)
const filesToUpload: Ref<Array<File>> = ref([])

const elementsToUpload: Ref<Array<any>> = ref([])
provide('elementsToUpload', elementsToUpload)

const uploading = ref(false)
const progress1 = ref(0)
const progress2 = ref(0)

async function getFile(fileEntry) {
  try {
    return new Promise((resolve, reject) => fileEntry.file(resolve, reject))
  } catch (err) {
    console.log(err)
  }
}

const dataTransferItems = ref([])
provide('dataTransferItems', dataTransferItems)

if (props.dataTransferItems) {
  dataTransferItems.value = props.dataTransferItems
}

async function uploadItem(item, parent) {
  if (item.isDirectory) {
    let newDirectory = await userApi.createDocumentTreeDirectory(props.project?.value.id, {
      name: item.name,
      order: 0,
      parentId: parent,
    })

    let directoryReader = item.createReader()
    directoryReader.readEntries((entries) => {
      entries.forEach((entry) => {
        uploadItem(entry, newDirectory.id)
      })
    })
  } else {
    let file = await getFile(item) // Wait until we have the file
    let newFile = await userApi.uploadDocumentTreeFiles(props.project?.value.id, [file], parent, onUploadProgress)
  }
}

function onUploadProgress(progressEvent) {
  progress1.value = progressEvent.progress
}

function sleep(timeMs) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeMs)
  })
}

async function uploadAllElements() {
  uploading.value = true

  console.log('uploading to', props.currentDirectory)

  let itemNb = 0
  for (let i = 0; i < dataTransferItems.value.length; ++i) {
    const item = dataTransferItems.value[i]
    await uploadItem(item, props.currentDirectory?.id)
    // await sleep(4000);
    itemNb++
    progress2.value = itemNb / dataTransferItems.value.length
  }

  emit('ok', filesToUpload.value)
}

const progressLabel1 = computed(() => {
  ;(progress1.value * 100).toFixed(2) + '%'
})

const progressLabel2 = computed(() => {
  ;(progress2.value * 100).toFixed(2) + '%'
})
</script>

<template>
  <q-dialog ref="dialogRef" no-backdrop-dismiss no-esc-dismiss>
    <BaseDialog
      title="Importer des fichiers"
      :is-dialog-loading="isLoading"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <ImportFiles></ImportFiles>
        <div v-if="uploading">
          <div style="font-weight: 700; margin-top: 10px">Progression :</div>
          Fichier en cours :
          <q-linear-progress size="25px" :value="progress1" color="primary">
            <div class="absolute-full flex flex-center">
              <!-- <q-badge color="white" text-color="accent" /> -->
            </div>
          </q-linear-progress>

          Total :
          <q-linear-progress size="25px" :value="progress2" color="primary" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
              <!-- <q-badge color="white" text-color="accent" /> -->
            </div>
          </q-linear-progress>
        </div>
      </template>
      <template #actions>
        <q-btn color="primary" @click="uploadAllElements" flat label="Importer" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped></style>
