<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  modelValue: any
  label?: string
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue'])

const value = computed({
  get: () => props.modelValue,
  set: (value: any | undefined) => {
    emit('update:modelValue', value)
  },
})
</script>
<template>
  <q-checkbox v-model="value" :label="label" v-bind="$attrs" />
</template>
<style lang="scss" scoped></style>
