<script setup lang="ts">
import { ref, watch, inject, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { TaskTypeEnum } from '@/models/projects/TaskType.enum.ts'
import { Task } from '@/models/projects/Task'
import clip from 'text-clipper'
import { ProjectKey } from '@/models/symbols'
import ProjectTaskAction from './task_actions/ProjectTaskAction.vue'
import ProjectTaskProgression from './ProjectTaskProgression.vue'
import ProjectAssigneeSelector from './ProjectAssigneeSelector.vue'
import ProjectDeadlineInput from './ProjectDeadlineInput.vue'
import LogPanel from './log/LogPanel.vue'
import SendboxPanel from './sendbox/SendBoxPanel.vue'
import UpdateDocumentDialog from './task_actions/document_generation/dialog/UpdateDocumentDialog.vue'
import { getNode } from './documents/DocumentsCommon'

interface Props {
  task: Task | undefined
  updateNb: any
}
const props = defineProps<Props>()

const project = inject(ProjectKey)
const documentId = inject('documentId')
// const documentSelected = inject("documentSelected");
const selectedNode = inject('documentTreeSelectedNode')
const currentDocument = ref(undefined)
const lateralTab = inject('lateralTab')
const documentTreeNodes = inject('documentTreeNodes')

const route = useRoute()
const router = useRouter()
const store = useStore()

const tab = ref('details')

if (lateralTab.value) {
  tab.value = lateralTab.value
  console.log('lateral panel - new tab', lateralTab.value)
}

console.log('lateral panel - displayed tab', tab.value)

const taskTypeEnumType = TaskTypeEnum
const showFullDescription = ref(false)
const descriptionLength = ref(0)

const timer = ref(-1)
provide('timer', timer)

if (props.task != undefined) {
  descriptionLength.value = props.task.description.replace(/<[^>]+>/g, '').length
  showFullDescription.value = props.task.description.length < 300
}

function toggleShowFullDescription() {
  showFullDescription.value = !showFullDescription.value
}

function isTaskReadOnly() {
  // return !props.task.permissions.includes("execute_legacytask");
  return false
}

watch(
  () => props.task,
  () => {
    if (props.task != undefined) {
      showFullDescription.value = false
      descriptionLength.value = props.task.description.replace(/<[^>]+>/g, '').length
    }
  },
)

watch(
  () => lateralTab.value,
  () => {
    console.log('lateral tab - query parameter changed', lateralTab.value)
    if (lateralTab.value == undefined) {
      return
    }
    tab.value = lateralTab.value
  },
)

const logPanelUpdateNb = ref(0)

console.log('documentId', documentId.value)
if (documentId.value) {
  console.log('lateral panel - in document', documentId.value)
}

// watch(() => documentId.value, () => {
//   console.log("lateral panel - documentId changed");
//   updateDocument();
// });

watch(
  () => selectedNode.value,
  () => {
    console.log('lateral panel - selectedNode changed')
    updateDocument()
  },
)

watch(
  () => tab.value,
  () => {
    console.log('lateral panel - tab changed', tab.value)
    onTabChanged()
  },
)

function updateDocument() {
  console.log('lateral panel - update document', selectedNode.value)
  if (selectedNode.value == undefined) {
    currentDocument.value = undefined
  } else {
    currentDocument.value = getNode(documentTreeNodes.value, selectedNode.value)
  }
}

watch(
  () => props.updateNb,
  () => {
    logPanelUpdateNb.value = logPanelUpdateNb.value + 1
    timer.value = 60 * 5
  },
)

setInterval(() => {
  if (timer.value >= 0) {
    timer.value = timer.value - 1
  }
}, 1000)

function formatTimer() {
  if (timer.value % 60 >= 10) {
    return Math.floor(timer.value / 60) + ':' + (timer.value % 60)
  } else {
    return Math.floor(timer.value / 60) + ':0' + (timer.value % 60)
  }
}

function onTabChanged() {
  console.log('new tab selected', tab.value)

  const newRouteQuery = {
    lateralTab: tab.value,
  }

  if (route.query.documentId != undefined) {
    newRouteQuery.documentId = route.query.documentId
  }

  if (route.query.taskId != undefined) {
    newRouteQuery.taskId = route.query.taskId
  }

  if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        projectId: route.params.projectId.toString(),
      },
      query: newRouteQuery,
    })
  } else {
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
      },
      query: newRouteQuery,
    })
  }
}

const notificationsLabel = ref('Notifications')

watch(
  () => timer.value,
  () => {
    if (timer.value == -1) {
      notificationsLabel.value = 'Notifications'
    } else {
      notificationsLabel.value = 'Notifications&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;'
    }
  },
)
//
</script>

<template>
  <div style="margin-top: 0px">
    <q-tabs v-model="tab" dense class="text-grey" active-color="primary" indicator-color="primary" align="justify"
      narrow-indicator>
      <q-tab name="details" label="Détails" no-caps />
      <q-tab name="discussions" label="Discussions" no-caps />
      <q-tab name="notifications" no-caps>
        <div v-html="notificationsLabel" />
        <q-badge v-if="timer != -1" color="red" floating>{{ formatTimer() }}</q-badge>
      </q-tab>
    </q-tabs>
    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="details" v-if="task == undefined && currentDocument == undefined" class="tab-panel">
        <q-scroll-area style="height: 100%; max-width: 30000px">
          <div class="row no-wrap">
            <!-- <div class="col"></div> -->
            <div class="col-auto"
              style="font-size: 18px; font-weight: 500; color: #42526e; margin-top: 8px; margin-bottom: 16px">
              Dossier
            </div>
            <!-- <div class="col"></div> -->
          </div>
          <div class="row field items-baseline">
            <div class="field-title document-field-title col-auto">
              <p>Nom</p>
            </div>
            <div class="q-pl-sm col">
              {{ project.name }}
            </div>
          </div>
          <!-- <div class="row field items-baseline">
            <div class="field-title col-lg-3 col-md-4">
              <p>Nom du dossier</p>
            </div>
            <div class="q-pl-sm col-lg-9 col-md-8">
              {{ project.name }}
            </div>
          </div> -->
        </q-scroll-area>
      </q-tab-panel>

      <q-tab-panel name="details" v-if="task == undefined && currentDocument != undefined" class="tab-panel">
        <q-scroll-area style="height: 100%; max-width: 30000px">
          <div class="row no-wrap">
            <!-- <div class="col"></div> -->
            <div class="col-auto"
              style="font-size: 18px; font-weight: 500; color: #42526e; margin-top: 8px; margin-bottom: 16px">
              Document
            </div>
            <!-- <div class="col"></div> -->
          </div>
          <div class="row field items-baseline">
            <div class="field-title document-field-title col-auto">
              <p>Nom</p>
            </div>
            <div class="q-pl-sm col">
              {{ currentDocument.name }}
            </div>
          </div>
          <div class="row field items-baseline">
            <div class="field-title document-field-title col-auto">
              <p>Type</p>
            </div>
            <div class="q-pl-sm col">
              <div v-if="currentDocument.type == 1">Dossier</div>
              <div v-if="currentDocument.type == 2">Fichier</div>
              <div v-if="currentDocument.type == 3">Version de fichier</div>
            </div>
          </div>
        </q-scroll-area>
      </q-tab-panel>
      <q-tab-panel name="details" v-if="task != undefined" class="tab-panel">
        <q-scroll-area style="height: 100%; max-width: 30000px">
          <div class="row no-wrap">
            <!-- <div class="col"></div> -->
            <div class="col-auto"
              style="font-size: 18px; font-weight: 500; color: #42526e; margin-top: 8px; margin-bottom: 16px">
              Tâche
            </div>
            <!-- <div class="col"></div> -->
          </div>

          <div class="row field items-baseline" style="">
            <div class="field-title task-field-title col-auto">
              <p>Nom</p>
            </div>
            <div class="q-pl-md col">
              {{ task.name }}
            </div>
          </div>
          <div class="row field items-baseline">
            <div class="field-title task-field-title col-auto">
              <p>Responsable</p>
            </div>
            <div class="q-pl-md col" style="margin-left: 0px; margin-bottom: 8px">
              <ProjectAssigneeSelector :task="task" :borderless="true"
                :isDisable="project.isReadOnly || task.readOnly || isTaskReadOnly()"></ProjectAssigneeSelector>
            </div>
          </div>
          <div class="row field items-baseline">
            <div class="field-title task-field-title col-auto">
              <p>Échéance</p>
            </div>
            <div class="q-pl-md col" style="margin-left: 0px; margin-bottom: 8px; max-width: 160px">
              <ProjectDeadlineInput :task="task" :borderless="true"
                :isDisable="project.isReadOnly || task.readOnly || isTaskReadOnly()"></ProjectDeadlineInput>
            </div>
          </div>
          <div class="row field items-baseline">
            <div class="field-title task-field-title col-auto">
              <p>Progression</p>
            </div>
            <div class="q-pl-md col" style="margin-bottom: 8px">
              <ProjectTaskProgression :task="task" :isDisable="project.isReadOnly || task.readOnly || isTaskReadOnly()">
              </ProjectTaskProgression>
            </div>
          </div>
          <div class="row field" v-if="task.description">
            <div class="field-title task-field-title col-auto">
              <p>Description</p>
            </div>
            <div class="q-pl-md col">
              <div v-html="showFullDescription ? task.description : clip(task.description, 300, { html: true, maxLines: 5 })
                "></div>
              <div v-if="descriptionLength > 300" class="q-pl-xs read-more cursor-pointer" color="primary"
                @click="toggleShowFullDescription">
                {{ showFullDescription ? 'voir moins...' : 'voir plus...' }}
              </div>
            </div>
          </div>
          <div class="row field" v-if="task.type !== taskTypeEnumType.Information">
            <ProjectTaskAction :task="task"></ProjectTaskAction>
          </div>
        </q-scroll-area>
      </q-tab-panel>

      <q-tab-panel name="discussions" class="tab-panel">
        <LogPanel :task="task" :updateNb="logPanelUpdateNb"></LogPanel>
      </q-tab-panel>

      <q-tab-panel name="notifications" class="tab-panel">
        <SendboxPanel :updateNb="logPanelUpdateNb" :task="task"></SendboxPanel>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/app.variables.scss';

:deep(.field) {

  // See JIRA ticket detail tab
  .field-title {
    // color: gray;
    color: #42526e;
    // font-family: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif";
    font-size: 0.98em;
    font-weight: 600;
  }

  .document-field-title {
    width: 50px;
  }

  .task-field-title {
    width: 80px;
  }

  .read-more {
    color: #3d73c5;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 8px;
  }
}

.tab-panel {
  height: calc(100vh - $header_height - 3px - $tab_panel_header_height);
  background-color: $page_background_color;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

// :deep(.q-tab-panel) {

// }
</style>
